import { css, createGlobalStyle } from 'styled-components';

// CSS Reset
const reset = css`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body,
  #root {
    height: 100%;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  button {
    background-color: initial;
    border: none;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  p,
  h6,
  h5,
  h4,
  h3,
  h2,
  h1,
  ul,
  li {
    margin: 0;
    padding: 0;
  }
`;

// Fade site in when fonts have loaded
const webfontsReady = css`
  html {
    opacity: 0;
    transition: opacity 300ms ease-in-out;

    /*
      This class gets added
      by WebKit when fonts have
      loaded
    */
    &.wf-active {
      opacity: 1;
    }
  }
`;

export default createGlobalStyle`
  ${reset}
  ${webfontsReady}
`;