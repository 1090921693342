import React from "react";

import InstagramIcon from "assets/icons/instagram.svg";
import FacebookIcon from "assets/icons/facebook.svg";
import TwitterIcon from "assets/icons/twitter.svg";
import LinkedinIcon from "assets/icons/linked-in.svg";

import {
  FooterWrapper,
  FooterInner,
  FooterColumn,
  Nav,
  Item,
  Icons,
  IconLink,
  Icon
} from "./Footer.css";

const Footer = () => (
  <FooterWrapper>
    <FooterInner>
      <FooterColumn>
        <Nav to="/about">
          <Item isTitle={true}>ABOUT</Item>
          <Item>About Us</Item>
          <Item>Mission</Item>
          <Item>Our Team</Item>
          <Item>Request a quote</Item>
        </Nav>
      </FooterColumn>
      <FooterColumn>
        <Nav to="/products">
          <Item isTitle={true}>PRODUCTS</Item>
          <Item>Living Systems</Item>
          <Item>Water Management</Item>
        </Nav>
      </FooterColumn>
      <FooterColumn>
        <Nav to="/services">
          <Item isTitle={true}>SERVICES</Item>
          <Item>Design Consultancy</Item>
          <Item>Installation</Item>
          <Item>Maintenance</Item>
          <Item>Programming</Item>
        </Nav>
      </FooterColumn>
      <FooterColumn>
        <Nav to="/community">
          <Item isTitle>COMMUNITY</Item>
          <Item>Upcoming Events</Item>
          <Item>Get Involved</Item>
          <Item>The Living Roof</Item>
        </Nav>
      </FooterColumn>
      <FooterColumn>
        <Nav to="/portfolio">
          <Item isTitle={true}>PORTFOLIO</Item>
          <Item>Green Roof</Item>
          <Item>Urban Agriculture</Item>
        </Nav>
      </FooterColumn>
      <FooterColumn>
        <Nav to="/faq">
          <Item isTitle={true}>FAQ</Item>
          <Item>List of FAQs</Item>
        </Nav>
        <Icons>
          <IconLink href="https://www.instagram.com/greensulate/?hl=en">
            <Icon src={InstagramIcon} />
          </IconLink>
          <IconLink href="https://www.facebook.com/greensulate/">
            <Icon src={FacebookIcon} />
          </IconLink>
          <IconLink href="https://twitter.com/greensulate?lang=en">
            <Icon src={TwitterIcon} />
          </IconLink>
          <IconLink href="https://www.linkedin.com/company/greensulate-llc/">
            <Icon src={LinkedinIcon} />
          </IconLink>
        </Icons>
      </FooterColumn>
    </FooterInner>
  </FooterWrapper>
);

export default Footer;
