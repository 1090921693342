import styled, { css } from "styled-components";

import { imageShadow } from "styles/helpers/shadows";
import { color } from "styles/helpers/colors";
import { mediumUp } from "styles/mixins/mediaqueries.css";

const ImgWrapper = styled.div`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding-top: ${({ width, height }) => 100 / (width / height)}%;
  z-index: 1;
  transition: opacity 250ms ease-in-out;
  opacity: ${props => (props.hasLoaded ? 1 : 0)};

  @media ${mediumUp} {
    padding-top: ${({ mdWidth, mdHeight }) => 100 / (mdWidth / mdHeight)}%;
  }

  ${props =>
    props.color &&
    css`
      box-shadow: ${imageShadow(color(props.color))};
    `};

  ${props =>
    props.circular &&
    css`
      border-radius: 50%;
    `};
`;

const ShadowWrapper = styled.div`
  position: relative;
  transition: opacity 250ms ease-in-out;
  opacity: ${props => (props.hasLoaded ? 1 : 0)};
  ${props =>
    props.color &&
    css`
      padding-left: 8px;
      padding-bottom: 8px;

      /*
        This creates that 3d effect on the shadow
        by adding small boxes for depth
        using the ::before and ::after
        pseudo selectors
      */

      &::before {
        content: "";
        position: absolute;
        left: 3px;
        top: 2px;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background-color: ${color(props.color)};
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 3px;
        right: 2px;
        width: 12px;
        height: 12px;
        transform: rotate(-45deg);
        background-color: ${color(props.color)};
      }
    `};
`;

const ImgEl = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 250ms ease-in-out;
  opacity: ${props => (props.hasLoaded ? 1 : 0)};
`;

const BgImg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 250ms ease-in-out;
  opacity: ${props => (props.hasLoaded ? 1 : 0)};
`;

export { ImgWrapper, ShadowWrapper, ImgEl, BgImg };
