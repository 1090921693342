
// Site specific

/*
  This breakpoint represents the point at which the site's max width
  meets the gutters (which are applied below this breakpoint)
*/

export const siteMaxPlusGuttersUp = `(min-width: 1252px)`;

// Mobile portrait
export const tiny = `(max-width: 349px)`;
export const mobile = `(min-width: 350px) and (max-width: 559px)`;
export const mobileUp = `(min-width: 350px)`;
export const mobileDown = `(max-width: 559px)`;

// Mobile landscape
export const small = `(min-width: 560px) and (max-width: 767px)`;
export const smallUp = `(min-width: 560px)`;
export const smallDown = `(max-width: 767px)`;

// Tablet portrait
export const medium = `(min-width: 768px) and (max-width: 1023px)`;
export const mediumUp = `(min-width: 768px)`;
export const mediumDown = `(max-width: 1023px)`;

// Tablet landscape
export const large = `(min-width: 1024px) and (max-width: 1279px)`;
export const largeUp = `(min-width: 1024px)`;
export const largeDown = `(max-width: 1279px)`;

// Larger screens
export const wide = `(min-width: 1280px) and (max-width: 1599px)`;
export const wideUp = `(min-width: 1280px)`;
export const wideDown = `(max-width: 1599px)`;
export const max = `(min-width: 1600px)`;

// Orientations
export const portrait = `(orientation: portrait)`;
export const landscape = `(orientation: landscape)`;

// Device detection
export const noTouch = `(pointer: fine)`;
export const iPhoneX = `(min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)`;

export default {
  tiny,
  mobile,
  mobileUp,
  mobileDown,
  small,
  smallUp,
  smallDown,
  medium,
  mediumUp,
  mediumDown,
  large,
  largeUp,
  largeDown,
  wide,
  wideUp,
  wideDown,
  portrait,
  landscape,
  siteMaxPlusGuttersUp
};