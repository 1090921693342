import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TypeKit from "react-typekit";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

import ScrollReset from "components/ScrollReset";

ReactDOM.render(
  <Router>
    <>
      <TypeKit kitId="ays5fpr" />
      <ScrollReset>
        <App />
      </ScrollReset>
    </>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
