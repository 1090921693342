import styled, { css } from "styled-components";

import GraphPaper from "assets/images/graph-paper.png";

import { size } from "styles/helpers/sizing";
import { color } from "styles/helpers/colors";
import { col } from "styles/mixins/grid.css";
import { siteMaxWidth } from "styles/mixins/layout.css";
import { mediumUp } from "styles/mixins/mediaqueries.css";
import { paragraphSmall } from "styles/mixins/type.css";
import { sansBold } from "styles/mixins/fonts.css";

import { Link } from "react-router-dom";

const FooterWrapper = styled.div`
  display: none;

  @media ${mediumUp} {
    position: relative;
    display: block;
    padding-top: ${size(10)};
    padding-bottom: ${size(12)};

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-image: url(${GraphPaper});
      background-position: center center;
    }
  }
`;

const FooterInner = styled.div`
  ${siteMaxWidth} display: flex;
  max-width: 75rem;
`;

const FooterColumn = styled.div`
  ${col(2)};
  padding-right: ${size(6)};
`;

const Nav = styled(Link)`
  ${paragraphSmall} display: inline-block;
  transition: 250ms ease-in-out;

  &:hover {
    > span {
      opacity: 0.65;
    }
  }
`;

const Item = styled.span`
  ${paragraphSmall}
  color: ${color("forest")};
  margin-bottom: ${size(2)};
  display: block;
  transition: 250ms ease-in-out;

  ${props =>
    props.isTitle &&
    css`
    ${sansBold}
    margin-bottom: ${size(3)};
  `}
`;

const Icons = styled.div`
  margin-top: ${size(12)};
  white-space: nowrap;
`;

const IconLink = styled.a.attrs({
  rel: "noopener",
  target: "_blank"
})`
  display: inline-block;
  transition: 250ms ease-in-out;

  &:hover {
    opacity: 0.65;
  }

  &:not(:last-of-type) {
    margin-right: ${size(5)};
  }
`;

const Icon = styled.img`
  width: 19px;
  height: 19px;
`;

export {
  FooterWrapper,
  FooterInner,
  FooterColumn,
  Nav,
  Item,
  Icons,
  IconLink,
  Icon
};
