import { css } from "styled-components";

import { size, font, track } from "styles/helpers/sizing";
import { color } from "styles/helpers/colors";

import { sansRegular, sansBold } from "styles/mixins/fonts.css";
import { largeUp, mediumUp } from "styles/mixins/mediaqueries.css";

const navItem = css`
  ${sansRegular};
  font-size: ${font(2)};
  letter-spacing: ${track(4)};
`;

const jumbo = css`
  ${sansRegular} line-height: 1;
  font-size: ${font(9)};

  @media ${mediumUp} {
    font-size: 6.25rem; // 100px
  }
`;

const title = css`
  ${sansRegular}
  font-size: ${font(6)};
  letter-spacing: ${track(7)};

  @media ${largeUp} {
    font-size: ${font(9)};
    letter-spacing: ${track(8)};
  }
`;

const paragraph = css`
  ${sansRegular}
  font-size: ${font(2)};
  letter-spacing: ${track(6)};

  @media ${largeUp} {
    font-size: ${font(6)};
    letter-spacing: ${track(7)}
  }
`;

const paragraphSmall = css`
  ${sansRegular} font-size: 0.625rem; // 10px
  letter-spacing: ${track(4)};

  @media ${largeUp} {
    font-size: ${font(2)};
  }
`;

const small = css`
  ${sansRegular}
  font-size: ${font(1)};
  letter-spacing: ${track(4)};
`;

const markdown = css`
  > {
    color: ${color("currentColor")};
  }

  p {
    ${paragraphSmall};
    margin-bottom: ${size(6)};
  }

  h1, h2 {
    ${small}
    ${sansBold}
    margin-bottom: ${size(4)};
  }

  h3 {
    ${paragraph}
    margin-bottom: ${size(6)};
  }

  ul, ol {
    ${paragraphSmall};
    list-style-position: outside;
    padding-left: 6%;
  }

  a {
    color: ${color("currentColor")};
    cursor: pointer;
    transition: 250ms ease-in-out;

    &:hover {
      opacity: 0.65;
    }
  }

  blockquote {

  }

  strong {

  }
`;

export { title, paragraph, paragraphSmall, small, markdown, jumbo, navItem };
