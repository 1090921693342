import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _throttle from "lodash/throttle";
import _isEmpty from "lodash/isEmpty";
import get from "utils/get";

import Malarquee from "react-malarquee";

import { StripButton } from "components/base";
import {
  SubFooterWrapper,
  Left,
  Right,
  MarqueeContainer
} from "./SubFooter.css";

const MEDIUM_UP = 768;

class SubFooter extends PureComponent {
  constructor(props) {
    super(...arguments);
    this.state = {
      isMobile: false
    };

    this.throttledResizeHandler = null;
  }

  componentDidMount() {
    this.checkWindowWidth();
    this.throttledResizeHandler = _throttle(this.checkWindowWidth, 25);

    window.addEventListener("resize", this.throttledResizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.throttledResizeHandler);
  }

  checkWindowWidth = () => {
    const width = window.innerWidth;

    if (width < MEDIUM_UP) {
      if (this.state.isMobile) return;
      return this.didBecomeMobile();
    }

    if (width >= MEDIUM_UP) {
      if (!this.state.isMobile) return;
      return this.didBecomeDesktop();
    }
  };

  didBecomeMobile = () => {
    this.setState({ isMobile: true });
  };

  didBecomeDesktop = () => {
    this.setState({ isMobile: false });
  };

  render() {
    const data = get(this, "props.data");

    if (!data || data.isError || _isEmpty(data)) return null;
    return (
      <SubFooterWrapper>
        {this.state.isMobile ? (
          <Fragment>
            <StripButton to="mailto:grow@greensulate.com">
              Request a quote
            </StripButton>
            <StripButton to="mailto:info@greensulate.com" color="lagoon">
              Contact Us
            </StripButton>
          </Fragment>
        ) : (
          <Fragment>
            <Left>
              <StripButton to="mailto:grow@greensulate.com">
                Request a quote
              </StripButton>
              <StripButton to="mailto:info@greensulate.com" color="lagoon">
                Contact Us
              </StripButton>
            </Left>
            <Right>
              <MarqueeContainer>
                <Malarquee rate={60} hoverToPause>
                  {`${get(data, "fields.text")}`}
                </Malarquee>
              </MarqueeContainer>
            </Right>
          </Fragment>
        )}
      </SubFooterWrapper>
    );
  }
}

SubFooter.defaultProps = {
  marqueeText: `This is default text!`
};

SubFooter.propTypes = {
  marqueeText: PropTypes.string
};

export default SubFooter;
