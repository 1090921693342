import styled from "styled-components";

import { size } from "styles/helpers/sizing";
import { color } from "styles/helpers/colors";
import { col } from "styles/mixins/grid.css";
import { mediumUp } from "styles/mixins/mediaqueries.css";
import { small } from "styles/mixins/type.css";

const SubFooterWrapper = styled.div`
  ${col(12)} display: flex;
  flex-direction: column;

  @media ${mediumUp} {
    flex-direction: row;
  }
`;

const Left = styled.div`
  ${col(5)};
  display: flex;
`;

const Right = styled.div`
  ${col(7)};
`;

const MarqueeContainer = styled.div`
  ${small}
  padding: ${size(4)} 0;
  background-color: ${color("mint")};
  color: ${color("white")};

  span {
    padding-right: 1rem;
  }
`;

export { SubFooterWrapper, Left, Right, MarqueeContainer };
