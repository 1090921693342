import { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

class ScrollReset extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollReset.defaultProps = {
  location: {}
};

ScrollReset.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string
  }),
  children: PropTypes.node.isRequired
};

export default withRouter(ScrollReset);
