import React from "react";
import { withRouter } from "react-router-dom";
import Fade from "react-reveal/Fade";

import GlobalDataProvider, {
  GlobalDataContext
} from "contexts/GlobalDataContext";

import { LayoutWrapper } from "styles/mixins/layout.css";
import Navigation from "components/Navigation/Navigation";
import SubFooter from "components/SubFooter/SubFooter";
import Footer from "components/Footer/Footer";

const Layout = ({ location, children }) => {
  return (
    <>
      <GlobalDataProvider>
        <LayoutWrapper>
          <Navigation location={location} />
          {children}
          <GlobalDataContext.Consumer>
            {context => (
              <Fade>
                <SubFooter data={context.data} />
              </Fade>
            )}
          </GlobalDataContext.Consumer>
          <Fade>
            <Footer />
          </Fade>
        </LayoutWrapper>
      </GlobalDataProvider>
    </>
  );
};

export default withRouter(Layout);
