import React, { Component } from "react";
import GlobalStyle from "styles/mixins/global.css";

import { withRouter } from "react-router-dom";

import Routes from "Routes";

import ContentfulClient from "lib/ContentfulClient";
import ContentfulData from "lib/ContentfulData";

class App extends Component {
  componentDidMount() {
    // Setup Contentful
    const contentful = ContentfulClient();
    ContentfulData.setRef(contentful);
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <Routes location={this.props.location} />
      </>
    );
  }
}

export default withRouter(App);
