import styled, { css } from "styled-components";

import { NavLink } from "react-router-dom";

import { HEIGHTS } from "constants/Sizes";
import GraphPaper from "assets/images/graph-paper.png";
import DropdownCarrot from "assets/glyphs/dropdown-carrot.svg";
import DropdownCarrotOrange from 'assets/glyphs/dropdown-carrot-orange.svg';

import { size } from "styles/helpers/sizing";
import { color } from "styles/helpers/colors";
import { siteMaxWidth } from "styles/mixins/layout.css";
import { wideUp, largeUp } from "styles/mixins/mediaqueries.css";
import { navItem } from "styles/mixins/type.css";

const Header = styled.div`
  display: none;

  @media ${largeUp} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: block;
    height: ${HEIGHTS.header}px;
    padding: ${props => (props.isHomePage ? 0 : `0  ${size(9)}`)};
    background: ${color("white")};

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background-image: url(${GraphPaper});
      background-position: center center;
    }
  }
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: ${size(5)};

  @media ${wideUp} {
    padding-left: 5%;
  }

  ${props =>
    props.isHomePage &&
    css`
    ${siteMaxWidth}

    @media ${wideUp} {
      padding-left: 0;
    }
  `};
`;

const NavItem = styled(NavLink)`
  ${navItem};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${color("forest")};
  transition: 250ms ease-in-out;

  &:hover {
    opacity: 0.75;
  }

  &.active {
    color: ${color("orange")};
  }
`;

const NavItemWithDropDown = styled(NavItem)`
  display: flex;
  position: relative;
  color: ${color("forest")};
  opacity: 1;

  &::after {
    content: "";
    background-image: url(${DropdownCarrot});
    background-position: 50% 52%;
    background-repeat: no-repeat;
    width: 64px;
    height: 64px;
  }

  > span {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    opacity: 0;
    pointer-events: none;
    transition: 250ms ease-in-out;
  }

  &:hover {
    opacity: 1;

    > div, &::after {
      opacity: 0.75;
    }

    > span {
      display: block;
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.active {
    &::after {
      background-image: url(${DropdownCarrotOrange});
    }
  }
`;

const Dropdown = styled.span`
  opacity: 1;
  min-width: 210px;
  display: block;
  background-color: ${color("white")};
  padding: ${size(5)};
`;

const DropdownNavItem = styled(NavItem)`
  text-transform: capitalize;
  white-space: nowrap;
  padding: ${size(5)} 0;
`;

const MobileHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-left: ${size(5)};
  padding-right: ${size(5)};
  height: ${HEIGHTS.mobileHeader}px;
  background-color: ${color("white")};

  @media ${largeUp} {
    display: none;
  }
`;

const MobileHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const LogoLink = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: auto;
  max-width: 150px;

  @media ${largeUp} {
    max-width: 286px;
  }
`;

/*
  Mobile Menu
*/

const Hamburger = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;

  span:first-of-type {
    top: 0;
    transition: 250ms ease-in-out;
  }

  span:nth-of-type(2n) {
    top: 50%;
    transform: translateY(-50%);
    transition: 10ms ease-in-out;
  }

  span:last-of-type {
    bottom: 0;
    transition: 250ms ease-in-out;
  }

  ${props =>
    props.isOpen &&
    css`
      span:first-of-type {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        background-color: ${color("mint")};
      }

      span:nth-of-type(2n) {
        opacity: 0;
      }

      span:last-of-type {
        bottom: 50%;
        transform: translateY(50%) rotate(45deg);
        background-color: ${color("mint")};
      }
    `};
`;

const Toppings = styled.span`
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: ${color("sky")};
`;

const MobileMenu = styled.div`
  background-color: ${color("white")};
  position: fixed;
  top: ${HEIGHTS.mobileHeader}px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 250ms ease-in-out;
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(-100%)")};
  z-index: 20;
`;

const MobileNavItem = styled(NavLink)`
  ${navItem};
  color: ${color("forest")};
  text-transform: capitalize;
  display: block;
  padding: ${size(2)} ${size(5)};
  padding-bottom: ${size(2)};
  border-bottom: solid 1px ${color("sky")};

  /*
    FAQ should be uppercase
  */
  &:last-of-type {
    text-transform: uppercase;
  }

  &.active {
    color: ${color("orange")};
  }
`;

const MobileSubNavItem = styled(MobileNavItem)`
  margin-left: ${size(5)};
`;

export {
  Header,
  HeaderInner,
  MobileHeader,
  MobileHeaderInner,
  Hamburger,
  Toppings,
  LogoLink,
  Logo,
  MobileMenu,
  Nav,
  NavItem,
  NavItemWithDropDown,
  Dropdown,
  DropdownNavItem,
  MobileNavItem,
  MobileSubNavItem
};
