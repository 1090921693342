// Margin/padding sizes
const sizes = [
  `0.125rem`, // size(1) | 2px
  `0.25rem`,  // size(2) | 4px
  `0.5rem`,   // size(3) | 8px
  `0.75rem`,  // size(4) | 12px
  `1rem`,     // size(5) | 16px
  `1.25rem`,  // size(6) | 20px
  `1.5rem`,   // size(7) | 24px
  `1.75rem`,  // size(8) | 28px
  `2rem`,     // size(9) | 32px
  `2.5rem`,   // size(10) | 40px
  `3rem`,     // size(11) | 48px
  `4rem`,     // size(12) | 64px
  `5rem`,     // size(13) | 80px
  `6rem`,     // size(14) | 96px
  `7rem`,     // size(15) | 112px
];

// Font sizes
const fonts = [
  `0.812rem`, // font(1) | 13px
  `1rem`,     // font(2) | 16px
  `1.125rem`, // font(3) | 18px
  `1.25rem`,  // font(4) | 20px
  `1.5rem`,   // font(5) | 24px
  `1.687rem`, // font(6) | 27px
  `1.75rem`,  // font(7) | 28px
  `2rem`,     // font(8) | 32px
  `2.25rem`,  // font(9) | 36px
  `2.5rem`,   // font(10) | 40px
  `3rem`,     // font(11) | 48px
  `3.5rem`,   // font(12) | 56px
];

// Tracking sizes (letter-spacing)
const trackings = [
  `0`,          // tracking(1) | 0
  `0.01875rem`, // tracking(2) | 0.3px
  `0.0375rem`,  // tracking(3) | 0.6px
  `0.071rem`,   // tracking(4) | 1.13px
  `0.075rem`,   // tracking(5) | 1.2px
  `0.081rem`,   // tracking(6) | 1.29px
  `0.101rem`,   // tracking(7) | 1.61px
  `0.209rem`,   // tracking(8) | 3.35px
];

// Border radius sizes
const radii = [
  `0.25rem`,  // radius(1) | 4px
  `0.375rem`, // radius(2) | 6px
  `0.75rem`,  // radius(3) | 12px
];


/*
  Helper functions
  e.g. 
    size(1) === 0.125rem / 2px
    font(1) === 0.812rem / 13px
    etc.
*/

const size = (num) => sizes[num - 1];
const font = (num) => fonts[num - 1];
const track = (num) => trackings[num - 1];
const radius = (num) => {
  if (num === 'circle') {
    return `50%`
  }
  return radii[num - 1];
}

export { size, font, track, radius };

