import { css } from 'styled-components';

const sansStack = `"nimbus-sans-extended", Helvetica, Arial, sans-serif`;

const sansRegular = css`
  font-style: normal;
  font-family: ${sansStack};
  font-weight: 400;
`;

const sansBold = css`
  font-style: normal;
  font-family: ${sansStack};
  font-weight: 700;
`;

export { sansRegular, sansBold };