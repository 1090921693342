import styled, { css } from "styled-components";

import { siteMaxPlusGuttersUp, mediumUp } from "styles/mixins/mediaqueries.css";

import { HEIGHTS } from "constants/Sizes";

const siteMaxWidth = css`
  padding: 0 1.625rem; /* 26px */
  max-width: 62.5rem; /* 1200px */
  margin: 0 auto;

  @media ${siteMaxPlusGuttersUp} {
    padding: 0;
  }
`;

const LayoutWrapper = styled.div`
  padding-top: ${HEIGHTS.mobileHeader}px;

  @media ${mediumUp} {
    padding-top: ${HEIGHTS.header}px;
  }
`;

export { siteMaxWidth, LayoutWrapper };
