import React from "react";

import {
  RoundButtonWrapper,
  RoundButtonInner,
  StripButtonWrapper
} from "./Button.css";

const RoundButton = ({ label, path, size, theme }) => (
  <RoundButtonWrapper to={path} size={size}>
    <RoundButtonInner theme={theme}>{label}</RoundButtonInner>
  </RoundButtonWrapper>
);

const StripButton = ({ color, to, children }) => (
  <StripButtonWrapper href={to} target="_blank" colorVariant={color}>
    {children}
  </StripButtonWrapper>
);

StripButton.defaultProps = {
  to: "#"
};

export { RoundButton, StripButton };
