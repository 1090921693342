const PATHS = {
  root: "/"
};

const HEADER = {
  links: {
    about: {
      label: "about",
      path: "/about"
    },
    products: {
      label: "products",
      path: "/products",
      links: {
        livingSystems: {
          label: "living systems",
          path: "/products/living-systems"
        },
        sustainableOptions: {
          label: "sustainable options",
          path: "/products/sustainable-options"
        }
      }
    },
    services: {
      label: "services",
      path: "/services"
    },
    community: {
      label: "community",
      path: "/community"
    },
    portfolio: {
      label: "portfolio",
      path: "/portfolio"
    },
    faq: {
      label: "faq",
      path: "/FAQ"
    }
  }
};

export { PATHS, HEADER };
