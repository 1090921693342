const colors = {
  white: `#FFFFFF`,
  forest: `#1D5550`,
  mint: `#70907D`,
  sky: `#E7F0EF`,
  lagoon: `#01909D`,
  pink: `#EED9D8`,
  orange: `#CD5822`,
  currentColor: `currentColor`,
};

export const color = name => colors[name];
