import ContainerBase from "lib/ContainerBase";
import ContentfulData from "lib/ContentfulData";

class EssentialFarmersContainer extends ContainerBase {
  view = import("views/View");

  model = () => {
    return ContentfulData.getEntries({
      content_type: "page",
      "fields.slug": "essentialfarmers",
      include: 4
    }).then(res => res.items[0]);
  };
}

export default EssentialFarmersContainer;
