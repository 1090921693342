import { css } from 'styled-components';

import {
  mobileUp,
  smallUp,
  mediumUp,
  largeUp,
  wideUp,
} from './mediaqueries.css';

const COLUMN_COUNT = 12;

const withBreakpoint = (percentageWidth, bp) => {
  switch (bp) {
    case 'xs':
      return css`
        @media ${mobileUp} {
          width: ${percentageWidth};
        }
      `;
    case 'sm':
      return css`
        @media ${smallUp} {
          width: ${percentageWidth};
        }
      `;
    case 'md':
      return css`
        @media ${mediumUp} {
          width: ${percentageWidth};
        }
      `;
    case 'lg':
      return css`
        @media ${largeUp} {
          width: ${percentageWidth};
        }  
      `;
    case 'xl':
      return css`
        @media ${wideUp} {
          width: ${percentageWidth};
        }
      `;
    default:
      return css`
        width: ${percentageWidth};
      `
  }
}


export const col = (num, bp) => {
  const percentageWidth = `${num/COLUMN_COUNT * 100}%`
  return withBreakpoint(percentageWidth, bp);
}