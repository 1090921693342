import React, { Component } from "react";

import ContentfulData from "lib/ContentfulData";

const GlobalDataContext = React.createContext({});

class GLobalDataProvider extends Component {
  state = { data: {} };

  componentDidMount() {
    this.getGlobalData();
  }

  getGlobalData = () => {
    return ContentfulData.getEntries({
      content_type: "marquee",
      include: 2
    }).then(res => this.setState({ data: res.items[0] }));
  };

  render() {
    return (
      <GlobalDataContext.Provider value={this.state}>
        {this.props.children}
      </GlobalDataContext.Provider>
    );
  }
}

export { GlobalDataContext };
export default GLobalDataProvider;
