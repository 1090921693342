import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { size } from "styles/helpers/sizing";
import { color } from "styles/helpers/colors";
import { buttonShadow } from "styles/helpers/shadows";
import { mediumUp } from "styles/mixins/mediaqueries.css";
import { small } from "styles/mixins/type.css";
import { sansBold } from "styles/mixins/fonts.css";

const RoundButtonWrapper = styled(Link)`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${mediumUp} {
    position: initial;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    flex: none;
  }
`;

const RoundButtonInner = styled.span`
  ${small}
  color: ${color("sky")};
  white-space: nowrap;
  text-decoration: underline;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props =>
    props.theme === "dark" ? color("forest") : color("mint")};
  padding-top: ${size(3)};
  padding-bottom: ${size(3)};

  @media ${mediumUp} {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;

    ${props =>
      props.theme &&
      css`
        box-shadow: ${buttonShadow(
          props.theme === "dark" ? color("mint") : color("forest")
        )};
        transition: 250ms ease-in-out;

        &:hover {
          background: ${props.theme === "dark"
            ? color("mint")
            : color("forest")};
          box-shadow: ${buttonShadow(
            props.theme === "dark" ? color("forest") : color("mint")
          )};
        }
      `};
  }
`;

const StripButtonWrapper = styled.a`
  ${small};
  ${sansBold};
  background-color: ${props =>
    props.colorVariant ? color(props.colorVariant) : color("forest")};
  padding: ${size(4)} 0;
  text-align: center;
  display: inline-block;
  color: ${color("white")};
  text-decoration: underline;
  width: 100%;
  transition: 250ms ease-in-out;

  &:hover {
    color: ${color("orange")};
  }
`;

export { RoundButtonWrapper, RoundButtonInner, StripButtonWrapper };
