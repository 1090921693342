import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { ImgWrapper, ShadowWrapper, ImgEl, BgImg } from "./Img.css";

class Img extends PureComponent {
  constructor(props) {
    super(...arguments);

    this.state = {
      imageLoaded: false
    };

    this.loader = null;
  }

  componentDidMount() {
    const { src } = this.props;

    const loader = new Image();
    loader.onload = this.imageDidLoad;
    loader.src = src;
  }

  imageDidLoad = () => {
    this.setState({ imageLoaded: true });
  };

  render() {
    const {
      bg,
      src,
      alt,
      width,
      height,
      mdWidth,
      mdHeight,
      color,
      circular
    } = this.props;

    return (
      <ShadowWrapper color={color} hasLoaded={this.state.imageLoaded}>
        <ImgWrapper
          color={color}
          width={width}
          height={height}
          mdWidth={mdWidth || width}
          mdHeight={mdHeight || height}
          circular={circular}
          hasLoaded={this.state.imageLoaded}
        >
          {!!bg ? (
            <BgImg src={src} hasLoaded={this.state.imageLoaded} />
          ) : (
            <ImgEl src={src} alt={alt} hasLoaded={this.state.imageLoaded} />
          )}
        </ImgWrapper>
      </ShadowWrapper>
    );
  }
}

Img.defaultProps = {
  bg: false,
  src: "",
  alt: "",
  width: 0,
  height: 0,
  circular: false
};

Img.propTypes = {
  bg: PropTypes.bool,
  color: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  mdWidth: PropTypes.number,
  mdHeight: PropTypes.number,
  circular: PropTypes.bool
};

export default Img;
