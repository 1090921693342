import React from 'react';
import PropTypes from 'prop-types';
import Remarkable from 'react-remarkable';
import { MarkdownWrapper } from './Markdown.css';

const Markdown = ({ src, color }) => (
  <MarkdownWrapper color={color}>
    <Remarkable source={src} />
  </MarkdownWrapper>
);

Markdown.defaultProps = {
  color: 'forest'
};

Markdown.propTypes = {
  src: PropTypes.string,
  color: PropTypes.string,
};

export default Markdown;