import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import { PATHS, HEADER } from "mocks/Navigation";
import LogoSrc from "assets/images/logo.png";

import get from "utils/get";
import { freezeScroll, unfreezeScroll } from "utils/manageScrollingElement";

import {
  Header,
  HeaderInner,
  MobileHeader,
  MobileHeaderInner,
  Hamburger,
  Toppings,
  LogoLink,
  Logo,
  MobileMenu,
  Nav,
  NavItem,
  NavItemWithDropDown,
  Dropdown,
  DropdownNavItem,
  MobileNavItem,
  MobileSubNavItem
} from "./Navigation.css";

const { about, products, services, community, portfolio, faq } = HEADER.links;

class Navigation extends PureComponent {
  state = {
    isOpen: false
  };

  componentDidMount() {
    unfreezeScroll();
  }

  handleToggle = () => {
    if (this.state.isOpen) {
      return this.closeMenu();
    }
    this.openMenu();
  };

  openMenu = () => {
    this.setState({ isOpen: true });
    freezeScroll();
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
    unfreezeScroll();
  };

  render() {
    const isHomePage = get(this, "props.location.pathname") === PATHS.root;

    return (
      <Fragment>
        <Header isHomePage={isHomePage}>
          <HeaderInner>
            {!isHomePage && (
              <LogoLink to={PATHS.root}>
                <Logo src={LogoSrc} />
              </LogoLink>
            )}
            <Nav isHomePage={isHomePage}>
              <NavItem to={about.path}>
                <span>{about.label}</span>
              </NavItem>
              <NavItemWithDropDown to={products.path}>
                <div>{products.label}</div>
                <Dropdown>
                  <DropdownNavItem to={products.links.livingSystems.path}>
                    <span>{products.links.livingSystems.label}</span>
                  </DropdownNavItem>
                  <DropdownNavItem to={products.links.sustainableOptions.path}>
                    <span>{products.links.sustainableOptions.label}</span>
                  </DropdownNavItem>
                </Dropdown>
              </NavItemWithDropDown>
              <NavItem to={services.path}>
                <span>{services.label}</span>
              </NavItem>
              <NavItem to={community.path}>
                <span>{community.label}</span>
              </NavItem>
              <NavItem to={portfolio.path}>
                <span>{portfolio.label}</span>
              </NavItem>
              <NavItem to={faq.path}>
                <span>{faq.label}</span>
              </NavItem>
            </Nav>
          </HeaderInner>
        </Header>
        <MobileHeader>
          <MobileHeaderInner>
            <LogoLink to={PATHS.root}>
              <Logo src={LogoSrc} />
            </LogoLink>
            <Hamburger onClick={this.handleToggle} isOpen={this.state.isOpen}>
              <Toppings />
              <Toppings />
              <Toppings />
            </Hamburger>
          </MobileHeaderInner>
        </MobileHeader>
        <MobileMenu isOpen={this.state.isOpen}>
          <MobileNavItem to={about.path}>
            <span>{about.label}</span>
          </MobileNavItem>
          <>
            <MobileNavItem to={products.path}>
              <span>{products.label}</span>
            </MobileNavItem>
            <MobileSubNavItem to={products.links.livingSystems.path}>
              <span>{products.links.livingSystems.label}</span>
            </MobileSubNavItem>
            <MobileSubNavItem to={products.links.sustainableOptions.path}>
              <span>{products.links.sustainableOptions.label}</span>
            </MobileSubNavItem>
          </>
          <MobileNavItem to={services.path}>
            <span>{services.label}</span>
          </MobileNavItem>
          <MobileNavItem to={community.path}>
            <span>{community.label}</span>
          </MobileNavItem>
          <MobileNavItem to={portfolio.path}>
            <span>{portfolio.label}</span>
          </MobileNavItem>
          <MobileNavItem to={faq.path}>
            <span>{faq.label}</span>
          </MobileNavItem>
        </MobileMenu>
      </Fragment>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default Navigation;
