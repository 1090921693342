import styled from 'styled-components';

import { color } from 'styles/helpers/colors';
import { markdown } from 'styles/mixins/type.css';

const MarkdownWrapper = styled.div`
  color: ${props => color(props.color)};
  ${markdown}
`;

export { MarkdownWrapper };