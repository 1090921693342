import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import HomeContainer from "containers/HomeContainer";
import AboutContainer from "containers/AboutContainer";
import ProductsContainer from "containers/ProductsContainer";
import ProductsLivingSystemsContainer from "containers/ProductsLivingSystemsContainer";
import ProductsSustainableOptionsContainer from "containers/ProductsSustainableOptionsContainer";
import ServicesContainer from "containers/ServicesContainer";
import CommunityContainer from "containers/CommunityContainer";
import PortfolioContainer from "containers/PortfolioContainer";
import FAQContainer from "containers/FAQContainer";
import EssentialFarmersContainer from "containers/EssentialFarmersContainer";

const Routes = ({ location }) => (
  <Switch location={location}>
    <Route exact path="/" component={HomeContainer} />
    <Route exact path="/about" component={AboutContainer} />
    <Route exact path="/products" component={ProductsContainer} />
    <Route
      exact
      path="/products/living-systems"
      component={ProductsLivingSystemsContainer}
    />
    <Route
      exact
      path="/products/sustainable-options"
      component={ProductsSustainableOptionsContainer}
    />
    <Route exact path="/services" component={ServicesContainer} />
    <Route exact path="/community" component={CommunityContainer} />
    <Route exact path="/portfolio" component={PortfolioContainer} />
    <Route exact path="/FAQ" component={FAQContainer} />
    <Route exact path="/essentialfarmers" component={EssentialFarmersContainer} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
