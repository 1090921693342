const staging = {
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN
};

const production = {
  CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  CONTENTFUL_HOST: process.env.REACT_APP_CONTENTFUL_HOST,
  CONTENTFUL_ACCESS_TOKEN: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
};

const PRODUCTION = process.env.REACT_APP_STAGE === "production";

const toExport = PRODUCTION ? production : staging;

export default toExport;
